<template>
    <div class="min-h-screen flex flex-col">
      <nav-menu />
      <!-- Mobile Header -->
      <div class="block md:hidden bg-secondary text-white text-3xl p-4">
        {{ customer.company }}
      </div>
      <!-- Desktop Header -->
      <div class="sticky top-0 hidden h-20 z-50 md:flex items-center justify-center border-b bg-secondary text-white">
        <h1 class="text-4xl font-bold">{{ customer.company }}</h1>
      </div>
  
      <div class="flex flex-1">
        <!-- Desktop Layout -->
        <div class="hidden md:flex w-full">
          <!-- Middle Column: Projects List -->
          <div class="w-3/12 p-4 border-r">
            <h2 class="text-lg font-semibold mb-4">Customer Projects</h2>
            <div v-if="projects.length ">
              <div
                v-for="project in projects"
                :key="project.projectId"
                
              >
              <div v-if="project.active" class="p-4 border rounded mb-4 bg-gray-50 relative z-10">
                <div v-if="project.active" class="absolute top-0 right-0 m-1 rounded bg-green-500 p-1 text-white font-bold text-xs">Active</div>
                <h3 class="text-xl font-bold">{{ project.projectName }}</h3>
                <p class="text-sm text-gray-600">{{ project.formattedAddress }}</p>
                <p class="text-sm">Contact: {{ project.contact }}</p>
                
                <a :href="'tel:' + project.phoen" class="text-sm">Phone: <span class="text-blue-500 underline"> {{ project.phone }}</span></a><br />
                <a :href="'mailto:' + project.email" class="text-sm">Email: <span class="text-blue-500 underline">{{ project.email }}</span></a>
                <p class="text-sm">PO: {{ project.poNumber }}</p>
              </div>
            </div>
            </div>
            <div v-else>
              <p>No projects found for this customer.</p>
            </div>
          </div>
  
          <!-- Left Column: Wall -->
          <div class="w-6/12 p-4 border-r">
            <!-- Note input -->
            <div class="mb-4 flex space-x-4">
              <input
                type="text"
                v-model="newPostContent"
                placeholder="Leave a note..."
                class="w-full p-2 border rounded"
              />
              <button
                @click="addPost"
                class="w-40 h-14 bg-blue-600 text-white px-8 flex items-center justify-center rounded"
              >
                Add Post
              </button>
            </div>
            <!-- Posts list -->
            <div v-if="posts.length < 1">
              <h2 class="text-gray-300 text-3xl my-40">No activity has been found</h2>
            </div>
            <div class="space-y-4">
              <div
                v-for="post in posts"
                :key="post.id"
                class="p-4 border rounded bg-gray-50 flex justify-between items-start"
              >
                <div class="text-left">
                  <!-- Display the post body (for type 'post') -->
                  <p class="text-gray-700 text-lg" v-if="post.type === 'post'">{{ post.body }}</p>
                  <!-- You can add additional formatting for other types if needed -->
                  <div class="text-xs text-gray-400">{{ $moment(post.created.seconds * 1000) }}</div>
                </div>
                <button
                  @click="deletePost(post.id)"
                  class="text-xs text-red-600 ml-2"
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
  
          <!-- Right Column: Contact Details & Documents -->
          <div class="w-3/12 p-4">
            <!-- Contact Details -->
            <!-- <div class="mb-6">
              <h2 class="text-lg font-semibold mb-2">Contact Details</h2>
              <div class="p-4 border rounded bg-gray-50">
                <p><strong>Company:</strong> {{ customer.company }}</p>
                <p v-if="customer.email">
                  <strong>Email:</strong> {{ customer.email }}
                </p>
                <p v-if="customer.phone">
                  <strong>Phone:</strong> {{ customer.phone }}
                </p>
              </div>
            </div> -->
            <!-- Documents Section -->
            <div>
              <h2 class="text-lg font-semibold mb-2">Attached Documents</h2>
              <div v-if="attachedFiles.length" class="space-y-2">
                <div
                  v-for="file in attachedFiles"
                  :key="file.id"
                  class="p-2 border rounded bg-gray-50"
                >
                  <div class="w-full text-xs text-gray-400 truncate w-40">
                    <span>{{ file.name }}</span>
                  </div>
                  <div class="flex items-center justify-center mt-2">
                    <a
                      :href="file.webUrl"
                      target="_blank"
                      class="ml-2 bg-blue-600 text-white px-2 py-1 rounded text-sm"
                    >
                      View
                    </a>
                    <button
                      @click="openSendModal(file)"
                      class="ml-2 bg-green-600 text-white px-2 py-1 rounded text-sm"
                    >
                      Send
                    </button>
                    <button
                      @click="deleteFile(file.id)"
                      class="ml-2 bg-red-600 text-white px-2 py-1 rounded text-sm"
                    >
                      Delete
                    </button>
                  </div>
                </div>
              </div>
              <div class="mt-4" v-if="user && user.outlookToken">
                <button
                  @click="openFilesModal"
                  class="w-full text-sm hover:bg-secondary cursor-pointer bg-primary h-12 px-4 flex items-center justify-center rounded text-white"
                >
                  Attach OneDrive File
                </button>
              </div>
            </div>
          </div>
        </div>
  
        <!-- Mobile Layout -->
        <div class="block md:hidden flex-1 p-4">
          <!-- Show current tab content -->
          <div v-if="currentTab === 'wall'">
            <h2 class="text-2xl font-semibold mb-4">Wall</h2>
            <div class="mb-4">
              <textarea
                v-model="newPostContent"
                placeholder="Leave a note..."
                class="w-full p-2 border rounded"
              ></textarea>
              <button
                @click="addPost"
                class="mt-2 bg-blue-600 text-white px-4 py-2 rounded"
              >
                Post
              </button>
            </div>
            <div class="space-y-4">
              <div
                v-for="post in posts"
                :key="post.id"
                class="p-4 border rounded bg-gray-50 flex justify-between items-start"
              >
                <div>
                  <p class="text-gray-700" v-if="post.type === 'post'">{{ post.body }}</p>
                  <div class="text-xs text-gray-500">{{ formatDate(post.created) }}</div>
                </div>
                <button
                  @click="deletePost(post.id)"
                  class="text-xs text-red-600 ml-2"
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
          <div v-else-if="currentTab === 'projects'">
            <h2 class="text-2xl font-semibold mb-4">Projects</h2>
            <div v-if="projects.length">
              <div
                v-for="project in projects"
                :key="project.projectId"
                class="p-4 border rounded mb-4 bg-gray-50"
              >
                <h3 class="text-xl font-bold">{{ project.projectName }}</h3>
                <p class="text-sm text-gray-600">{{ project.formattedAddress }}</p>
                <p class="text-sm">Contact: {{ project.contact }}</p>
                <p class="text-sm">Phone: {{ project.phone }}</p>
                <p class="text-sm">Email: {{ project.email }}</p>
                <p class="text-sm">PO: {{ project.poNumber }}</p>
              </div>
            </div>
            <div v-else>
              <p>No projects found for this customer.</p>
            </div>
          </div>
          <div v-else-if="currentTab === 'documents'">
            <!-- <h2 class="text-2xl font-semibold mb-2">Contact Details</h2>
            <div class="p-4 border rounded bg-gray-50 mb-4">
              <p><strong>Company:</strong> {{ customer.company }}</p>
              <p v-if="customer.email">
                <strong>Email:</strong> {{ customer.email }}
              </p>
              <p v-if="customer.phone">
                <strong>Phone:</strong> {{ customer.phone }}
              </p>
            </div> -->
            <h2 class="text-2xl font-semibold mb-2">Documents</h2>
            <div v-if="attachedFiles.length" class="space-y-2">
              <div
                v-for="file in attachedFiles"
                :key="file.id"
                class="flex items-center justify-between p-2 border rounded bg-gray-50"
              >
                <span>{{ file.name }}</span>
                <div class="flex items-center">
                  <a
                    :href="file.webUrl"
                    target="_blank"
                    class="ml-2 bg-blue-600 text-white px-2 py-1 rounded text-sm"
                  >
                    View
                  </a>
                  <button
                    @click="openSendModal(file)"
                    class="ml-2 bg-green-600 text-white px-2 py-1 rounded text-sm"
                  >
                    Send
                  </button>
                  <button
                    @click="deleteFile(file.id)"
                    class="ml-2 bg-red-600 text-white px-2 py-1 rounded text-sm"
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
            <div class="mt-4" v-if="user && user.outlookToken">
              <button
                @click="openFilesModal"
                class="w-full text-sm hover:bg-secondary cursor-pointer bg-primary h-12 px-4 flex items-center justify-center rounded text-white"
              >
                Attach OneDrive File
              </button>
            </div>
          </div>
        </div>
      </div>
  
      <!-- Mobile Footer Navigation -->
      <div
        class="md:hidden fixed bottom-0 left-0 right-0 bg-gray-200 flex justify-around p-2 border-t"
      >
        <button
          @click="currentTab = 'wall'"
          :class="{ 'text-blue-600': currentTab === 'wall' }"
        >
          Wall
        </button>
        <button
          @click="currentTab = 'projects'"
          :class="{ 'text-blue-600': currentTab === 'projects' }"
        >
          Projects
        </button>
        <button
          @click="currentTab = 'documents'"
          :class="{ 'text-blue-600': currentTab === 'documents' }"
        >
          Documents
        </button>
      </div>
  
      <!-- OneDrive Files Modal -->
      <div
        v-if="openFiles"
        class="w-full h-screen fixed overflow-hidden bg-gray-900 bg-opacity-50 flex z-50 items-center justify-center"
      >
        <div class="w-96 h-2/3 bg-white rounded-lg shadow-lg p-6 relative">
          <div class="absolute top-0 right-0 m-2">
            <div
              @click="closeFilesModal"
              class="cursor-pointer hover:bg-primary hover:text-white h-6 w-6 bg-gray-100 rounded flex items-center justify-center"
            >
              x
            </div>
          </div>
          <h2 class="header text-xl font-bold mb-4">One Drive Files</h2>
          <div v-if="loading" class="text-center">
            <span>Loading files...</span>
          </div>
          <div v-else>
            <div class="bg-gray-100 p-4 rounded-lg h-60 overflow-y-scroll">
              <button
                v-if="folderStack.length"
                @click="goBack"
                class="text-sm text-primary underline mb-2"
              >
                Back
              </button>
              <ul>
                <li
                  v-for="file in files"
                  :key="file.id"
                  class="flex items-center mb-2"
                >
                  <template v-if="file.metadata.folder">
                    <span
                      @click="toggleFolder(file)"
                      class="cursor-pointer flex items-center"
                    >
                      <img
                        src="https://static-00.iconduck.com/assets.00/folder-icon-256x204-0171zqe6.png"
                        alt="Folder"
                        class="w-5 h-5 mr-2"
                      />
                      {{ file.name }}
                    </span>
                  </template>
                  <template v-else>
                    <div
                      @click="selectFile(file)"
                      :class="[
                        'flex items-center cursor-pointer p-2 rounded',
                        selectedFiles.includes(file.id) ? 'bg-green-100 text-primary' : ''
                      ]"
                    >
                      <img
                        src="https://cdn-icons-png.flaticon.com/512/124/124837.png"
                        alt="File"
                        class="w-5 h-5 mr-2"
                      />
                      {{ file.name }}
                      <span
                        v-if="selectedFiles.includes(file.id)"
                        class="ml-auto text-green-600"
                      >
                        ✔
                      </span>
                    </div>
                  </template>
                </li>
              </ul>
            </div>
            <button
              v-if="selectedFiles.length"
              @click="attachFiles"
              class="mt-4 text-sm w-full bg-green-600 text-white py-2 rounded"
            >
              Attach Selected Files
            </button>
          </div>
        </div>
      </div>
  
      <!-- Send File Modal -->
      <div
        v-if="sendModal.file"
        class="top-0 left-0 w-full h-screen fixed overflow-hidden bg-gray-900 bg-opacity-50 flex z-50 items-center justify-center"
      >
        <div class="w-96 bg-white rounded-lg shadow-lg p-6">
          <h2 class="text-xl font-bold mb-4">Send File</h2>
          <textarea
            v-model="sendModal.message"
            placeholder="Enter your message here"
            class="w-full border p-2 rounded mb-4"
          ></textarea>
          <div class="text-gray-700 mb-4">{{ sendModal.file.name }}</div>
          <button
            @click="sendFile(sendModal.file)"
            class="w-full bg-blue-600 text-white py-2 rounded"
          >
            Send
          </button>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import axios from "axios";

  import { auth, db } from "../firebase";
import { onAuthStateChanged } from "firebase/auth";
import { startAt, endAt, deleteFire, onSnapshot, orderBy, collection, query, where, setDoc, getDocs, doc, getDoc, updateDoc, addDoc, deleteDoc, limit } from "firebase/firestore";


  import {
    getFire,
    setFiles,
    getFiles,
    deleteFile,
    postFire,
    getProjectByCustomerId,
  } from "../firestore/utils";
  import Outlook from "@/views/Outlook.vue";
  import NavMenu from "@/components/headers/navMenu";
  
  export default {
    data() {
      return {
        openFiles: false,
        customerId: null,
        customer: {},
        user: null,
        files: [],
        selectedFiles: [],
        folderStack: [],
        loading: false,
        attachedFiles: [],
        sendModal: { file: null, message: "" },
        projects: [],
        posts: [],
        newPostContent: "",
        currentTab: "wall", // for mobile navigation: wall, projects, documents
        unsubscribePosts: null,
      };
    },
    components: { Outlook, NavMenu },
    methods: {
      async getCustomer() {
        this.customer = await getFire({
          collection: "customers",
          doc: this.customerId,
        });
      },
      async getCurrentUser() {
        return new Promise((resolve, reject) => {
          const unsubscribe = onAuthStateChanged(auth, (user) => {
            unsubscribe();
            user ? resolve(user) : reject(new Error("No user is signed in."));
          });
        });
      },
      async getUser() {
        const _user = await this.getCurrentUser();
        this.user = await getFire({ collection: "users", doc: _user.uid });
      },
      async openFilesModal() {
        this.openFiles = true;
        this.selectedFiles = []; // Clear selected files
        this.loading = true;
        try {
          const response = await axios.post(
            "https://us-central1-ktc-app-33f33.cloudfunctions.net/widgets/onedrive/get-docs",
            {
              accessToken: this.user.outlookToken,
            }
          );
          this.files = response.data.files.map((file) => ({
            ...file,
            open: false,
            children: [],
          }));
        } catch (error) {
          console.error("Error fetching OneDrive files:", error);
        } finally {
          this.loading = false;
        }
      },
      closeFilesModal() {
        this.openFiles = false;
      },
      async toggleFolder(folder) {
        if (folder.open) {
          folder.open = false;
          return;
        }
        folder.open = true;
        this.folderStack.push(this.files);
        this.loading = true;
        try {
          const response = await axios.post(
            "https://us-central1-ktc-app-33f33.cloudfunctions.net/widgets/onedrive/get-folder-contents",
            {
              accessToken: this.user.outlookToken,
              folderId: folder.id,
            }
          );
          folder.children = response.data.files.map((file) => ({
            ...file,
            open: false,
            children: [],
          }));
          this.files = folder.children;
        } catch (error) {
          console.error("Error fetching folder contents:", error);
        } finally {
          this.loading = false;
        }
      },
      goBack() {
        if (this.folderStack.length > 0) {
          this.files = this.folderStack.pop();
        }
      },
      selectFile(file) {
        if (this.selectedFiles.includes(file.id)) {
          this.selectedFiles = this.selectedFiles.filter((id) => id !== file.id);
        } else {
          this.selectedFiles.push(file.id);
        }
      },
      async attachFiles() {
        try {
          for (const fileId of this.selectedFiles) {
            const file = this.files.find((f) => f.id === fileId);
            await setFiles({
              customerId: this.$route.query.customerId,
              body: file,
            });
          }
          this.openFiles = false;
          this.listenForFiles();
        } catch (error) {
          console.error("Error attaching files:", error);
        }
      },
      async listenForFiles() {
        this.loading = true;
        this.attachedFiles = await getFiles({
          customerId: this.$route.query.customerId,
        });
        this.loading = false;
      },
      async downloadFile(fileId) {
        try {
          const response = await axios.post(
            "https://us-central1-ktc-app-33f33.cloudfunctions.net/widgets/onedrive/download-doc",
            {
              accessToken: this.user.outlookToken,
              fileId: fileId,
            }
          );
          window.open(response.data.url, "_blank");
        } catch (error) {
          console.error("Error downloading file:", error);
        }
      },
      openSendModal(file) {
        this.sendModal.file = file;
        this.sendModal.message = "";
      },
      async sendFile(file) {
        try {
          const projects = await getProjectByCustomerId({
            customerId: this.customer.id,
          });
          await axios.post(
            "https://us-central1-ktc-app-33f33.cloudfunctions.net/widgets/onedrive/send-email",
            {
              email: projects[0].email, // Replace with recipient's email
              fileUrl: file.webUrl,
              message: this.sendModal.message,
            }
          );
          this.sendModal.file = null;
          alert("File successfully sent!");
        } catch (error) {
          console.error("Error sending file:", error);
        }
      },
      async deleteFile(fileId) {
        try {
          await deleteFile({
            customerId: this.$route.query.customerId,
            fileId: fileId,
          });
          this.listenForFiles();
        } catch (error) {
          console.error("Error deleting file:", error);
        }
      },
      async fetchProjects() {
        try {
          this.projects = await getProjectByCustomerId({
            customerId: this.customer.id,
          });
        } catch (error) {
          console.error("Error fetching projects:", error);
        }
      },
      // Listen live for posts in the customerPosts collection
      listenForPosts() {
  const postsQuery = query(
    collection(db, "customerPosts"),
    where("customerId", "==", this.customer.id),
    orderBy("created", "desc")
  );
  this.unsubscribePosts = onSnapshot(
    postsQuery,
    (snapshot) => {
      this.posts = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
    },
    (error) => {
      console.error("Error listening for posts:", error);
    }
  );
},
      // Add a new post to the wall (creates a new document in customerPosts)
      async addPost() {
        if (!this.newPostContent.trim()) return;
 
        try {

            const _user = await this.getCurrentUser();

            await postFire({
                collection: "customerPosts",
                body: {
                    postedBy: _user.uid,
                    type: "post",
                    body: this.newPostContent,
                    attachments: null,
                    customerId: this.customer.id,
                    created: new Date(),
                }
            })

          this.newPostContent = "";
        } catch (error) {
          console.error("Error adding post:", error);
        }
      },
      // Delete a post from the wall
      async deletePost(postId) {
        try {
            await deleteDoc(doc(db, "customerPosts", postId));
        } catch (error) {
          console.error("Error deleting post:", error);
        }
      },
      formatDate(dateString) {
        const date = new Date(dateString);
        return date.toLocaleString();
      },
    },
    async created() {
      this.customerId = this.$route.query.customerId;
      await this.getCustomer();
      await this.getUser();
      this.listenForFiles();
      await this.fetchProjects();
      this.listenForPosts();
    },
    beforeDestroy() {
      if (this.unsubscribePosts) {
        this.unsubscribePosts();
      }
    },
  };
  </script>
  
  <style scoped>
  /* Add any additional scoped CSS if necessary */
  </style>
  