<template>
    <div class="w-full">
        <div v-if="loading" class="flex items-center justify-center flex-col">
            <div class="flex items-center">
                <div class="loader"></div>
            <span class="ml-2 text-sm text-gray-400">Connecting to Outlook...</span>
            </div>
        </div>

        <div v-else>
            <button v-if="!connected" @click="login"
                class="bg-blue-600 text-white rounded h-12 flex items-center justify-center px-10 ml-auto">Login with
                Outlook</button>
            <div v-else class="w-40 bg-opacity-50 border border-green-600 text-green-600 bg-green-100 rounded-lg h-10 text-xs flex items-center justify-center px-2 ml-auto">
                <span>&#10003; Connected to Outlook</span>
            </div>
        </div>
    </div>  
</template>

<script>
import axios from 'axios';
import {
    getFire,
    putFire
} from "../firestore/utils";
import { auth } from "../firebase";

export default {
    data() {
        return {
            authorizationCode: '',
            refreshToken: '',
            connected: false,
            loading: true,
        };
    },
    methods: {
        async getCurrentUser() {
            return new Promise((resolve, reject) => {
                const unsubscribe = auth.onAuthStateChanged((user) => {
                    unsubscribe(); // Stop listening to further changes once resolved
                    if (user) {
                        resolve(user);
                    } else {
                        reject(new Error("No user is signed in."));
                    }
                });
            });
        },
        async getUser() {
            
            try {
                const user = await this.getCurrentUser();
                const userDoc = await getFire({ collection: "users", doc: user.uid });
                this.authorizationCode = userDoc.outlookToken;
                this.refreshToken = userDoc.refreshToken;
                await this.refreshMyToken();
            } catch (error) {
                console.error("Error getting user:", error);
            } finally {
                this.loading = false;
            }
        },
        async login() {
            const clientId = "c97f2516-2da9-46cb-8192-5bc9b9df46bc";
            // const redirectUri = "https://kctbookings.com.au/callbacks/azure"; // Redirect after login
            const redirectUri = "http://localhost:8080/callbacks/azure"; // Redirect after login
            const scope = "Calendars.ReadWrite Files.ReadWrite.All offline_access Sites.ReadWrite.All";

            // Generate PKCE code verifier and challenge
            this.codeVerifier = this.generateCodeVerifier();
            const codeChallenge = await this.generateCodeChallenge(this.codeVerifier);

            // Save the verifier in sessionStorage
            sessionStorage.setItem('code_verifier', this.codeVerifier);

            const oauthUrl = `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=${clientId}&response_type=code&redirect_uri=${redirectUri}&scope=${encodeURIComponent(scope)}&code_challenge=${codeChallenge}&code_challenge_method=S256`;
            window.location.href = oauthUrl;
        },
        async refreshMyToken() {
            const clientId = "c97f2516-2da9-46cb-8192-5bc9b9df46bc";
            const tenantId = "common"; // Use 'common' for multitenant apps or specific tenant ID
            const tokenUrl = `https://login.microsoftonline.com/${tenantId}/oauth2/v2.0/token`;

            if (!this.refreshToken) {
                console.error("No refresh token available.");
                this.connected = false;
                return;
            }

            const body = new URLSearchParams({
                client_id: clientId,
                grant_type: "refresh_token",
                refresh_token: this.refreshToken,
                scope: "Calendars.ReadWrite Files.ReadWrite.All offline_access Sites.ReadWrite.All",
            });

            try {
                const response = await fetch(tokenUrl, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded",
                    },
                    body: body.toString(),
                });

                if (!response.ok) {
                    const errorData = await response.json();
                    console.error("Error refreshing token:", errorData);
                    this.connected = false;
                    return;
                }

                const data = await response.json();
                console.log("New Access Token:", data.access_token);
                console.log("New Refresh Token:", data.refresh_token);

                const user = await this.getCurrentUser();

                await putFire({
                    collection: "users",
                    doc: user.uid,
                    body: { refreshToken: data.refresh_token, outlookToken:  data.access_token},
                });

                this.connected = true;
            } catch (error) {
                console.error("Error in token refresh:", error.message);
                this.connected = false;
            }
        },

        generateCodeVerifier() {
            const array = new Uint32Array(32);
            window.crypto.getRandomValues(array);
            return Array.from(array, (dec) => ('0' + dec.toString(16)).substr(-2)).join('');
        },

        async generateCodeChallenge(verifier) {
            const encoder = new TextEncoder();
            const data = encoder.encode(verifier);
            const digest = await crypto.subtle.digest('SHA-256', data);
            return btoa(String.fromCharCode(...new Uint8Array(digest)))
                .replace(/\+/g, '-')
                .replace(/\//g, '_')
                .replace(/=+$/, '');
        },

    },
    created() {
        this.getUser();
    },
};
</script>

<style>
.loader {
    border: 2px solid rgba(0, 0, 0, 0.1);
    border-left-color: #00cc14;
    border-radius: 50%;
    width: 26px;
    height: 26px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}
</style>
