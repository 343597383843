<template>
    <div>
    </div>
</template>

<script>
import axios from 'axios';
import { auth } from "../firebase";
import { onAuthStateChanged } from "firebase/auth";
import {
  putFire
} from "../firestore/utils";
export default {
    data() {
        return {
            accessToken: '',
            codeVerifier: null,
            event: {
                subject: '',
                start: '',
                end: '',
                reminderMinutesBeforeStart: 15,
            },
        };
    },
    created() {
        const code = this.$route.query.code;

        if (code) {
            this.getAccessToken(code);
        }
    },
    methods: {
        async login() {
            const clientId = "c97f2516-2da9-46cb-8192-5bc9b9df46bc";
            const redirectUri = "https://kctbookings.com.au/callbacks/azure"; // Redirect after login
            const scope = "Calendars.ReadWrite Files.ReadWrite.All offline_access Sites.ReadWrite.All";

            // Generate PKCE code verifier and challenge
            this.codeVerifier = this.generateCodeVerifier();
            const codeChallenge = await this.generateCodeChallenge(this.codeVerifier);

            // Save the verifier in sessionStorage
            sessionStorage.setItem('code_verifier', this.codeVerifier);

            const oauthUrl = `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=${clientId}&response_type=code&redirect_uri=${redirectUri}&scope=${encodeURIComponent(scope)}&code_challenge=${codeChallenge}&code_challenge_method=S256`;
            window.location.href = oauthUrl;
        },

       async getCurrentUser() {
    return new Promise((resolve, reject) => {
        const unsubscribe = auth.onAuthStateChanged((user) => {
            unsubscribe(); // Stop listening to further changes once resolved
            if (user) {
                resolve(user);
            } else {
                reject(new Error("No user is signed in."));
            }
        });
    });
},

        async getAccessToken(code) {


            const user = await this.getCurrentUser();

            const tokenUrl = "https://login.microsoftonline.com/common/oauth2/v2.0/token";
            const codeVerifier = sessionStorage.getItem('code_verifier');
            const data = new URLSearchParams({
                client_id: "c97f2516-2da9-46cb-8192-5bc9b9df46bc",
                redirect_uri: "http://localhost:8080/callbacks/azure",
                // redirect_uri: "https://kctbookings.com.au/callbacks/azure",
                grant_type: "authorization_code",
                code,
                code_verifier: codeVerifier,
            });

            try {
                const response = await axios.post(tokenUrl, data);
                this.accessToken = response.data.access_token;

                console.log("response.data tokens", response.data)
                // add users collection currentUser.uid 
                // add outlookToken: this.accessToken
              

                putFire({ collection: 'users', doc: user.uid, body: {outlookToken: this.accessToken, refreshToken: response.data.refresh_token} })
                this.$router.push("/crm")
            } catch (error) {
                if (error.response) {
                    console.error("Error Status:", error.response.status);
                    console.error("Error Data:", error.response.data);
                } else {
                    console.error("Error Message:", error.message);
                }
            }
        },

        async createEvent() {
            try {
                const response = await axios.post(
                    'https://graph.microsoft.com/v1.0/me/events',
                    {
                        subject: this.event.subject,
                        start: { dateTime: this.event.start, timeZone: 'UTC' },
                        end: { dateTime: this.event.end, timeZone: 'UTC' },
                        reminderMinutesBeforeStart: this.event.reminderMinutesBeforeStart,
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${this.accessToken}`,
                            'Content-Type': 'application/json',
                        },
                    }
                );
                console.log(response.data);
            } catch (error) {
                console.error('Error creating event:', error.message);
            }
        },

        generateCodeVerifier() {
            const array = new Uint32Array(32);
            window.crypto.getRandomValues(array);
            return Array.from(array, (dec) => ('0' + dec.toString(16)).substr(-2)).join('');
        },

        async generateCodeChallenge(verifier) {
            const encoder = new TextEncoder();
            const data = encoder.encode(verifier);
            const digest = await crypto.subtle.digest('SHA-256', data);
            return btoa(String.fromCharCode(...new Uint8Array(digest)))
                .replace(/\+/g, '-')
                .replace(/\//g, '_')
                .replace(/=+$/, '');
        },
        
    }
};
</script>